<!DOCTYPE html>
<template>
  <div>
    <header class="navbar navbar-header navbar-header-fixed">
      <a href="#" id="mainMenuOpen" class="burger-menu" @click="openMainMenu"><i class="fa fa-bars"></i></a>
      <div class="navbar-brand">
        <a href="#">
          <img v-if="iCedeClient==='core'" src="@/assets/img/brand/main_transp-icede.png" alt="iCede Logo" width="128" height="48"/>
          <scaled-image v-else
                        :src="require(`@/assets/img/icede-client/${iCedeClient}/logos/site-topbar-rightv2.png`)"
                        :alt="iCedeClient"
                        :height="48"
          />
          Portal
        </a>
      </div><!-- navbar-brand -->
      <div id="navbarMenu" class="navbar-menu-wrapper">
        <div class="navbar-menu-header">
          <a href="#"><img src="@/assets/img/brand/iCedeLogo.png" alt="iCede Logo" width="45" height="45"/></a>
          <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
        </div><!-- navbar-menu-header -->
        <ul class="nav navbar-menu">
          <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
          <li class="nav-item d-lg-none">
            <a href="#" @click="logout" class="nav-link"><i data-feather="pie-chart"></i> Logout</a>
          </li>
        </ul>
      </div><!-- navbar-menu-wrapper -->
      <div class="navbar-right">
        <a href="#" @click="logout" class="btn btn-outline-secondary">
          <log-out-icon />&nbsp;Logout
        </a>
      </div><!-- navbar-right -->
    </header><!-- navbar -->

    <div class="content content-fixed content-auth" :style="{backgroundImage: 'url(' + require('@/assets/img/double-bubble-outline-lighter.png') + ')'}">
      <div class="restricted-container">
        <div class="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div class="mx-5 wd-100p">
            <div class="wd-100p">
              <slide-y-up-transition mode="out-in" origin="center top">
                <router-view></router-view>
              </slide-y-up-transition>
            </div>
          </div><!-- sign-wrapper -->
        </div><!-- media -->
      </div><!-- container -->
    </div><!-- content -->

    <content-footer></content-footer>
  </div>
</template>
<script>
import {SlideYUpTransition} from 'vue2-transitions';
import ContentFooter from "@/layout/ContentFooter";

// TODO: install jQuery... Look online for any side affects of jQuery and Vue together
import "@/assets/js/dashforge.js";

// Dashforge styles
import "@/assets/css/dashforge.css";
import "@/assets/css/dashforge.auth.css";

import {LogOutIcon} from "vue-feather-icons";
import ScaledImage from "@/components/ScaledImage";
export default {
  name: "NewAuthLayout",
  components: {ScaledImage, ContentFooter, SlideYUpTransition, LogOutIcon},
  computed: {
    iCedeClient() {
      return process.env.VUE_APP_ICEDE_CLIENT.toLowerCase();
    }
  },
  methods: {
    openMainMenu() {
      document.getElementsByTagName('body')[0].classList.add('navbar-nav-show');
    },
    closeMainMenu() {
      document.getElementsByTagName('body')[0].classList.remove('navbar-nav-show');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({name: 'Login'});
    },
  },
  mounted() {

  }
}
</script>
