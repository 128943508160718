import ApiService from "@/services/api.service";

const ClaimService = {
    async getClaimsLike(client_id, term) {
        try {
            let { data } = await ApiService.post(
                'claim/getClaimsLike',
                {client_id, term}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    },
    async getClaimByID(client_id, claim_id) {
        try {
            let { data } = await ApiService.post(
                'claim/getClaimByID',
                {client_id, claim_id}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    },
    async getClaimsForLocalPolicy(client_id, local_policy_id) {
        try {
            let { data } = await ApiService.post(
                'claim/getClaimsForLocalPolicy',
                {client_id, local_policy_id}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    }
}

export default ClaimService;
