import ApiService from "@/services/api.service";

const UnderwritingService = {
    async getProgrammes(client_id) {
        try {
            let { data } = await ApiService.post(
                'programme',
                {client_id}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    },
    async getProgramme(client_id, master_policy_id) {
        try {
            let { data } = await ApiService.post(
                'getProgramme',
                {client_id, master_policy_id}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    },
    async getLocalPoliciesForProgramme(master_policy_id, client_id) {
        try {
            let { data } = await ApiService.post(
                'programme/localPolicies',
                {master_policy_id, client_id}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    },
    async getLocalPoliciesLike(client_id, term) {
        try {
            let { data } = await ApiService.post(
                'programme/localPoliciesLike',
                {client_id, term}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    },
    async getCoverageDocument(client_id, master_policy_id, local_policy_id) {
        try {
            let { data } = await ApiService.post(
                'programme/coverageDocument',
                {client_id, master_policy_id, local_policy_id}
            );
            return data;
        } catch (err) {
            if (err.response?.status !== 404) {
                // No coverage document for MPol and LPol combination
                console.error(err);
            }

            return {document: null};
        }
    },
    async getActivityFeed(client_id, timeframe) {
        try {
            let { data } = await ApiService.post(
                'activityFeed',
                {client_id, timeframe}
            );
            return data;
        } catch (err) {
            console.error(err);
        }
    }
}

export default UnderwritingService;
