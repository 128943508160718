import Vue from 'vue'
import VueRouter from 'vue-router'
import NewAuthLayout from '@/layout/NewAuthLayout'
import store from './store'
// import SwitchLayout from "@/layout/SwitchLayout";
import RouteGuardService from "@/services/routeGuard.service";
import NewDashboardLayout from "@/layout/NewDashboardLayout";
import FeaturesMixin from '@/mixins/features.mixin';
import SwitchLayout from "./layout/SwitchLayout";

Vue.use(VueRouter);

/**
 * Prevent VueRouter from throwing JS errors for harmless navigation failures.
 * Not necessary on the latest versions, but we can't upgrade to Vue3 at this stage (due to Bootstrap)
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)
        || VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.redirected)) {
      console.log("Suppressed navigation failure: " + err);
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }

  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)
        || VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.redirected)) {
      console.log("Suppressed navigation failure: " + err);
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior(){ // to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: 'clientSelect',
      component: NewDashboardLayout,
      children: [
        {
          path: '/dashboard',
          redirect: 'clientSelect',
          name: 'Dashboard',
          meta: {
            breadcrumbs: []
          },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "overview" */ './views/ComboDashboard.vue')
        },
        {
          path: '/activityFeed',
          name: 'Activity Feed',
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []}
            ]
          },
          component: () => import(/* webpackChunkName: "overview" */ './views/ActivityFeed.vue'),
        },
        {
          path: '/programmes',
          name: 'Programmes',
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []}
            ]
          },
          component: () => import(/* webpackChunkName: "overview" */ './views/Programmes.vue'),
        },
        {
          path: '/localPolicies',
          name: 'Local Policies',
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []}
            ]
          },
          component: () => import(/* webpackChunkName: "overview" */ './views/LocalPolicies.vue'),
        },
        {
          path: '/claims',
          name: 'Claims',
          meta: {
            disabled: !FeaturesMixin.methods.usingClaimsFeatures(),
            breadcrumbs: [
              {name: 'Dashboard', params: []}
            ]
          },
          component: () => import(/* webpackChunkName: "overview" */ './views/Claims.vue'),
        },
        {
          path: '/claims/:claimId',
          name: 'Claim Overview',
          props: true,
          meta: {
            disabled: !FeaturesMixin.methods.usingClaimsFeatures(),
            breadcrumbs: [
              {name: 'Dashboard', params: []},
              {name: 'Claims', params: []}
            ]
          },
          component: () => import(/* webpackChunkName: "overview" */ './views/Claim.vue'),
        },
        {
          path: '/programmes/view/:programmeId',
          name: 'Programme Overview',
          props: true,
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
              {name: 'Programmes', params: []}
            ]
          },
          component: () => import(/* webpackChunkName: "programmes" */ './views/ProgrammeOverview.vue'),
        },
        {
          path: '/programmes/view/:programmeId/localPolicy/:localPolicyId',
          name: 'Local Policy Overview',
          props: true,
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
              {name: 'Programmes', params: []},
              {name: 'Programme Overview', params: ['programmeId']}
            ]
          },
          component: () => import(/* webpackChunkName: "programmes" */ './views/LocalPolicyOverview.vue'),
        },
        {
          path: '/programmes/view/:programmeId/localPolicy/:localPolicyId/coverage/:coverageDocumentId',
          name: 'Coverage Document',
          props: true,
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
              {name: 'Programmes', params: []},
              {name: 'Programme Overview', params: ['programmeId']},
              {name: 'Local Policy Overview', params: ['programmeId', 'localPolicyId']}
            ]
          },
          component: () => import(/* webpackChunkName: "programmes" */ './views/CoverageDocument.vue'),
        },
        {
          path: '/profile',
          name: 'Profile',
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
            ]
          },
          component: () => import(/* webpackChunkName: "programmes" */ './views/UserProfile.vue')
        },
        {
          path: '/reports',
          name: 'Reports',
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
            ]
          },
          component: () => import(/* webpackChunkName: "programmes" */ './views/Reports.vue')
        },
        {
          path: '/wiki',
          name: 'Wiki',
          meta: {
            disabled: !FeaturesMixin.methods.wikiEnabled(),
            breadcrumbs: [
              {name: 'Dashboard', params: []},
            ]
          },
          component: () => import(/* webpackChunkName: "programmes" */ './views/Wiki.vue')
        },
        {
          path: '/contacts',
          name: 'Contacts',
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
            ]
          },
          component: () => import(/* webpackChunkName: "programmes" */ './views/Contacts.vue')
        },
        {
          path: '/overview',
          // Temporarily redirecting to dashboard, as there is only mock data on this view
          redirect: 'dashboard',
          name: 'Global Overview',
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
            ]
          },
          component: () => import(/* webpackChunkName: "overview" */ './views/GlobalOverview.vue')
        },
        {
          path: '/programmes/view/:programmeId/localPolicy/:localPolicyId/location/:locationId',
          // Temporarily redirecting to dashboard, as there is only mock data on this view
          name: 'Location',
          props: true,
          meta: {
            breadcrumbs: [
              {name: 'Dashboard', params: []},
              {name: 'Programmes', params: []},
              {name: 'Programme Overview', params: ['programmeId']},
              {name: 'Local Policy Overview', params: ['programmeId', 'localPolicyId']}
            ]
          },
          component: () => import(/* webpackChunkName: "overview" */ './views/Location.vue')
        },
        {
          path: '/viewTerms',
          name: 'ViewTerms',
          component: () => import(/* webpackChunkName: "auth" */ './views/Terms.vue'),
          meta: {
            openRoute: false,
            title: 'Terms',
          }
        },
        {
          path: '/changePassword',
          name: 'Change Password',
          component: () => import(/* webpackChunkName: "auth" */ './views/PasswordExpired.vue'),
          meta: {openRoute: false, title: 'Change Password', subtitle: 'Please enter a new password'}
        },
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: NewAuthLayout,
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "auth" */ './views/Login.vue'),
          meta: {openRoute: true}
        },
        {
          path: '/logout',
          name: 'Logout',
          component: () => import(/* webpackChunkName: "auth" */ './views/Login.vue'),
          meta: {openRoute: true}
        },
        {
          path: '/register',
          name: 'Register',
          component: () => import(/* webpackChunkName: "auth" */ './views/Register.vue'),
          meta: {openRoute: true}
        },
        {
          path: '/start/u/:userHash',
          name: 'Start',
          props: true,
          component: () => import(/* webpackChunkName: "auth" */ './views/Start.vue'),
          meta: {openRoute: true}
        },
        {
          path: '/forgotPassword/:userEmail',
          name: 'Forgot Password',
          props: true,
          component: () => import(/* webpackChunkName: "auth" */ './views/ForgotPassword.vue'),
          meta: {openRoute: true}
        },
        {
          path: '/resetPassword/user/:userId/code/:code',
          name: 'Reset Password',
          props: true,
          component: () => import(/* webpackChunkName: "auth" */ './views/ResetPassword.vue'),
          meta: {openRoute: true}
        },
      ]
    },
    {
      path: '/',
      redirect: 'clientSelect',
      component: SwitchLayout,
      children: [
        {
          path: '/twoFactor',
          name: 'Two Factor',
          component: () => import(/* webpackChunkName: "auth" */ './views/TwoFactor.vue'),
          meta: {
            openRoute: false,
            title: 'Two Factor Authentication',
            subtitle: 'Authenticate via the code sent to your device'
          }
        },
        {
          path: '/terms',
          name: 'Terms',
          component: () => import(/* webpackChunkName: "auth" */ './views/Terms.vue'),
          meta: {
            openRoute: true,
            title: 'Terms',
            subtitle: 'News users must accept the Terms of Use before proceeding'
          }
        },
        {
          path: '/clientSelect',
          name: 'Client Select',
          component: () => import(/* webpackChunkName: "auth" */ './views/ClientSelect.vue'),
          meta: {openRoute: false, title: 'Select Client', subtitle: 'Select a client to view their policies'}
        },
        {
          path: '/passwordExpired',
          name: 'Password Expired',
          component: () => import(/* webpackChunkName: "auth" */ './views/PasswordExpired.vue'),
          meta: {openRoute: false, title: 'Your Password Has Expired', subtitle: 'Please enter a new password'}
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const route = RouteGuardService.getNextRoute(to, from);

  if (to.name === "Logout") {
    store.dispatch('logout');
  }

  if (route !== '' && route !== to.path) {
    next(route);
    return;
  }

  if (to.meta?.disabled) {
    next({path: '/'});
    return;
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.name);

  document.title = process.env.VUE_APP_TITLE;

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    if (nearestWithTitle.name === "Login" && store.getters.isLoggedIn) {
      nearestWithTitle.name = "User";
    }
    document.title = nearestWithTitle.name + " - " + document.title;
  }


  next();
})

export default router
