<template>
  <div>
    <div class="wrapper">
      <aside v-if="sidebarShown" class="aside aside-fixed">
        <div class="aside-header">
          <a href="#" class="aside-logo">
            <scaled-image v-if="iCedeClient==='default'"
                          :src="require('@/assets/img/icede-client/default/logos/site-topbar-rightv2.png')"
                          :alt="'iCede Logo'"
                          :width="140"/>
            <scaled-image v-else
                          :src="require(`@/assets/img/icede-client/${iCedeClient}/logos/site-topbar-rightv2.png`)"
                          :alt="iCedeClient"
                          :width="216"
            />
          </a>
        </div>
        <div class="aside-body">
          <div class="aside-loggedin">
            <div class="d-flex align-items-center justify-content-start">
              <a href="" class="avatar">
                <img :src="'user-placeholder.jpg' | imageSource" class="rounded-circle" alt="">
              </a>
              <a style="margin-left: auto;" href="#" class="aside-menu-link d-md-inline-block d-lg-none" @click="toggleSidebar">
                <x-icon size="1.5x" />
              </a>
              <!--            <div class="aside-alert-link">-->
              <!--              <a href="#" class="new" data-toggle="tooltip" title="You have 2 unread messages"><message-square-icon></message-square-icon></a>-->
              <!--              <a href="#" class="new" data-toggle="tooltip" title="You have 4 new notifications"><bell-icon></bell-icon></a>-->
              <!--              <a href="#" @click="logout" data-toggle="tooltip" title="Sign out"><log-out-icon></log-out-icon></a>-->
              <!--            </div>-->
            </div>
            <div class="aside-loggedin-user">
              <a href="#loggedinMenu" class="d-flex align-items-center justify-content-between mg-b-2" data-toggle="collapse">
                <h6 class="tx-semibold mg-b-0">{{ user.forename + ' ' + user.surname }}</h6>
                <!--              <chevron-down-icon></chevron-down-icon>-->
              </a>
            </div>
            <div class="collapse" id="loggedinMenu">
              <ul class="nav nav-aside mg-b-0">
                <li class="nav-item"><a href="" class="nav-link"><edit-icon></edit-icon> <span>Edit Profile</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><user-icon></user-icon> <span>View Profile</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><settings-icon></settings-icon> <span>Account Settings</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><help-circle-icon></help-circle-icon> <span>Help Center</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="log-out"></i> <span>Sign Out</span></a></li>
              </ul>
            </div>
          </div><!-- aside-loggedin -->
          <ul class="nav nav-aside">
            <router-link tag="li" to="/programmes" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><globe-icon /><span>Programmes</span></a>
            </router-link>
            <router-link tag="li" to="/clientSelect" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><users-icon /><span>Clients List</span></a>
            </router-link>
            <router-link tag="li" to="/dashboard" class="hide nav-item" active-class="active">
              <a href="#" class="nav-link"><layout-icon /><span>Dashboard</span></a>
            </router-link>
            <router-link tag="li" to="/activityFeed" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><rss-icon /><span>Activity Feed</span></a>
            </router-link>
            <router-link tag="li" to="/localPolicies" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><book-open-icon /><span>Local Policies</span></a>
            </router-link>
            <router-link v-if="usingClaimsFeatures()" tag="li" to="/claims" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><cloud-lightning-icon /><span>Claims</span></a>
            </router-link>
            <router-link v-if="wikiEnabled()" tag="li" to="/wiki" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><list-icon /><span>Wiki</span></a>
            </router-link>
            <router-link tag="li" to="/viewTerms" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><book-icon /><span>Terms of Use</span></a>
            </router-link>
            <!--        Global Overview is temporarily disabled as it is only showing mock data    -->
            <!--            <router-link tag="li" to="/overview" class="nav-item" active-class="active">-->
            <!--              <a href="#" class="nav-link"><map-pin-icon></map-pin-icon><span>Global Overview</span></a>-->
            <!--            </router-link>-->
            <!--          <router-link tag="li" to="/tables" class="nav-item" active-class="active">-->
            <!--            <a class="nav-link"><list-icon></list-icon><span>Reports</span></a>-->
            <!--          </router-link>-->
            <li class="nav-label mg-t-25">User</li>
            <router-link tag="li" to="/profile" class="nav-item" active-class="active">
              <a href="#" class="nav-link"><user-icon /><span>User</span></a>
            </router-link>
            <li class="nav-item">
              <a href="#" class="nav-link" @click="logout"><log-out-icon /><span>Logout</span></a>
            </li>
          </ul>
        </div>
      </aside>

      <div class="content pd-0">
        <div class="content-header pl-3">
          <!--        <div class="content-search" style="flex-grow: 8;">-->
          <!--          <search-icon></search-icon>-->
          <!--          <input type="search" class="form-control" placeholder="Search...">-->
          <!--        </div>-->
          <!--        <nav class="nav" style="flex-grow: 1;">-->
          <!--          <a href="#" class="nav-link"><help-circle-icon></help-circle-icon></a>-->
          <!--          <a href="#" class="nav-link"><grid-icon></grid-icon></a>-->
          <!--          <a href="#" class="nav-link"><align-left-icon></align-left-icon></a>-->
          <!--        </nav>-->
          <div class="content-user-details text-left" style="flex-grow: 1;">
            <!--          <strong class="text-md">{{ user.forename + ' ' + user.surname }}</strong>&nbsp;-->
            <a href="#" class="aside-menu-link" @click="toggleSidebar">
              <menu-icon size="1.5x" />
            </a>
            <strong class="h5 ml-3">{{ client.client_name }}</strong>
            <!--          &nbsp;-->
            <!--          <a href="#">-->
            <!--            <img style="width: 45px; height: 45px;" src="https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg" class="rounded-circle" alt="">-->
            <!--          </a>-->
          </div>
          <div class="navbar-right">
            <a href="#" @click="logout" class="btn btn-outline-secondary">
              <log-out-icon />&nbsp;Logout
            </a>
          </div><!-- navbar-right -->
        </div><!-- content-header -->

        <div class="content-body" :style="{backgroundImage: 'url(' + require('@/assets/img/double-bubble-outline-lighter.png') + ')'}">
          <div class="container pd-x-0" style="margin-bottom:0;">
            <automatic-breadcrumbs />
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <!-- your content here -->
              <router-view></router-view>
            </fade-transition>
          </div><!-- container -->
          <div class="aside-backdrop" @click="toggleSidebar"></div>
          <content-footer style="margin-left: -25px;" v-if="!$route.meta.hideFooter"></content-footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Icons
import "@/assets/js/@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/js/ionicons/css/ionicons.min.css";
import "@/assets/js/jqvmap/jqvmap.min.css";
// Dashforge styles
import "@/assets/css/dashforge.css";
import "@/assets/css/dashforge.dashboard.css";
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import {
  UserIcon,
  UsersIcon,
  LogOutIcon,
  EditIcon,
  SettingsIcon,
  HelpCircleIcon,
  MenuIcon,
  XIcon,
  LayoutIcon,
  RssIcon,
  GlobeIcon,
  // MapPinIcon,
  BookOpenIcon,
  BookIcon,
  CloudLightningIcon,
  ListIcon,
} from 'vue-feather-icons';
import AutomaticBreadcrumbs from "@/components/AutomaticBreadcrumbs";
import ScaledImage from "@/components/ScaledImage";
import FeaturesMixin from "@/mixins/features.mixin";

export default {
  name: 'NewDashboardLayout',
  components: {
    ScaledImage,
    AutomaticBreadcrumbs,
    ContentFooter,
    FadeTransition,
    UserIcon,
    UsersIcon,
    LogOutIcon,
    EditIcon,
    SettingsIcon,
    HelpCircleIcon,
    MenuIcon,
    XIcon,
    LayoutIcon,
    RssIcon,
    GlobeIcon,
    // MapPinIcon,
    BookOpenIcon,
    BookIcon,
    CloudLightningIcon,
    ListIcon,
  },
  mixins: [FeaturesMixin],
  data() {
    return {
      sidebarShown: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    client() {
      return this.$store.getters.client;
    },
    iCedeClient() {
      return process.env.VUE_APP_ICEDE_CLIENT.toLowerCase();
    }
  },
  methods: {
    toggleSidebar() {
      if (this.sidebarShown) {
        this.closeMainMenu();
        this.sidebarShown = false;
      } else {
        this.openMainMenu();
        this.sidebarShown = true;
      }
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({name: 'Login'});
    },
    openMainMenu() {
      document.getElementsByTagName('body')[0].classList.add('show-aside');
    },
    closeMainMenu() {
      document.getElementsByTagName('body')[0].classList.remove('show-aside');
    },
  },
  mounted() {
    // TODO: It would be best to convert the code in this theme's library to use native Vue functionality... instead
    //  of jQuery
    // Dashforge Scripts
    require("@/assets/js/dashforge");
    // require("@/assets/js/dashboard-one");
    require("@/assets/js/dashforge.aside");

    const context = this;
    window.addEventListener('resize', function () {
      if (window.innerWidth > 991 && !context.sidebarShown) {
        context.sidebarShown = true;
        context.openMainMenu();
      } else if (window.innerWidth <= 991 && context.sidebarShown) {
        context.sidebarShown = false
        context.closeMainMenu();
      }
    });

    // Load countries
    this.$store.dispatch('loadState');
  }
};
</script>
<style lang="scss">
.content-body, .content, .wrapper, #app {
  min-height: calc(100vh - 60px);
  position: relative;
}
.container {
  padding-bottom: 35px;
}
footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
.content-body {
  background-blend-mode: multiply;
}
</style>
