import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from "vuex-persist";
// Module imports
import { underwriting } from './underwriting.js'
import { user } from './user.js'
import { countries } from './countries.js'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export default new Vuex.Store({
    modules: {
        underwriting,
        user,
        countries
    },
    state: {},
    mutations: {},
    actions: {
        async loadState({ dispatch }) {
            await dispatch("loadCountries");
        }
    },
    strict: process.env.NODE_ENV !== "production",
    plugins: [
        vuexLocal.plugin,
    ]
});
