import ApiService from "@/services/api.service";

export const countries = {
  state: () => ({
    countries: [],
    loadingCountries: false
  }),
  mutations: {
    clearCountries(state) {
      state.countries = [];
    },
    setCountries(state, payload) {
      state.countries = payload.countries;
    },
    setLoadingCountries(state, { loading }) {
      state.loadingCountries = loading;
    },
  },
  actions: {
    async loadCountries({ state, commit }) {
      let countries = state.countries;
      // if (countries.length !== 0) {
          // TODO decide when we should clear the local cache and reload countries, anyway...
        // return;
      // }

      try {
        commit("setLoadingCountries", { loading: true });
        let { data } = await ApiService.get(
          process.env.VUE_APP_API_ENDPOINT + "/country",
        );
        data = data.map(function(country) {
          country.checked = false;
          return country;
        });
        countries = data;
      } catch (err) {
        console.error(err);
      }

      commit("setLoadingCountries", { loading: false });

      commit({
        type: "setCountries",
        countries
      });
    },
    selectDeselectCountry({ commit, getters }, payload) {
      commit("checkUncheckCountry", payload);
      commit("setPolicyDetail", {
        emailAddress: getters.user?.email,
        clientName: "",
        clientRef: ""
      });
    },
    async loadCountryStateFromStorage({ dispatch }) {
      // TODO: check how long this takes... May have to rewrite this
      //  s.t. countries are selected as and when they are loaded by user interaction
      // All continents must be loaded...
      await dispatch("loadAllCountries");
    },
    async loadAllCountries({ dispatch, commit }) {
      commit("clearCountries");
      await dispatch("loadCountriesForContinent");
    }
  },
  getters: {
    getAllCountries: state => state.countries,
    countryStateForStorage: state => {
      // Only need to store selected country IDs
      const countryIds = state.countries
        .filter(country => country.checked)
        .map(country => country.country_id);
      return { selectedCountryIds: countryIds };
    },
    getCountriesByContinent: state => continent => {
      return state.countries.filter(country => country.continent === continent);
    },
    loadingCountries: state => state.loadingCountries,
    officesForCountry: state => country_id => {
      const country = state.countries.find(
        country => country.country_id === country_id
      );
      
      return country?.issuingOffices ?? [];
    }
  }
};
