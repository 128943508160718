<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb breadcrumb-style2">
      <li class="breadcrumb-item"
          v-for="(crumb, index) in breadcrumbs"
          :key="index"
          :class="{active: crumb.notLinked}"
      >
        <span v-if="crumb.notLinked">
          {{ crumb.name }}
        </span>
        <router-link v-else :to="{name: crumb.name, params: crumb.params}">
          {{ crumb.name }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>
<script>
export default {
  name: "AutomaticBreadcrumbs",
  computed: {
    breadcrumbs() {
      let crumbs = [];
      if (this.$route?.meta?.breadcrumbs === undefined) {
        return crumbs;
      }
      const routeBreadcrumbs = this.$route.meta.breadcrumbs;
      for (let crumb of routeBreadcrumbs) {
        let filledParams = {};
        if (crumb.params.length) {
          for (let param of crumb.params) {
            filledParams[param] = this.$route.params[param];
          }
        }
        crumb.params = filledParams;
        crumbs.push(crumb);
      }
      crumbs.push({ name: this.$route.name, notLinked: true });

      return crumbs;
    }
  },
}
</script>
